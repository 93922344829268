@use "@angular/material" as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();
// @import "~swiper/css";

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/

:root {
  // logo-colors
  --piggle-purple: #9999ff;
  --piggle-red: #f45e56;
  --piggle-yellow: #ffc836;
  // main bg
  --home-bg: #faebdf;
  // other
  --purple-dark: #3f4172;
  --citation: rgba(73, 75, 128, 0.57);
}

$studio-piggle-primary: mat.define-palette(mat.$indigo-palette);
$studio-piggle-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$studio-piggle-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$studio-piggle-theme: mat.define-light-theme(
  (
    color: (
      primary: $studio-piggle-primary,
      accent: $studio-piggle-accent,
      warn: $studio-piggle-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($studio-piggle-theme);

/* You can add global styles to this file, and also import other style files */
:host {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "cronos-pro-semibold";
  src: url("./assets/fonts/cronos-pro-semibold.otf");
  font-display: swap;
}

// FIGMA FROM HERE
html,
body {
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  position: relative;
  background-color: var(--home-bg) !important;
  margin: 0;
  font-family: "Roboto", "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
}

.bg-home {
  background-color: var(--home-bg) !important;
}

.text-purple-dark {
  color: var(--purple-dark) !important;
}

.text-citation {
  color: var(--citation) !important;
}

.bg-contact-btn {
  background-color: var(--dark-purple);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Merriweather" !important;
}

h1 {
  color: var(--purple-dark) !important;
}

a {
  color: var(--citation) !important;
  text-decoration: none !important;
  &:hover {
    color: var(--purple-dark) !important;
  }
  &:link,
  &:visited,
  &:hover,
  &:active {
    text-decoration: none;
  }
}

// logo css
.logo-2 {
  display: flex;
  flex-wrap: wrap;
}

.curved-corner {
  width: 100px;
  height: 100px;
  overflow: hidden;
  position: relative;
  &:before {
    content: "";
    display: block;
    width: 200%;
    height: 200%;
    position: absolute;
    border-radius: 50%;
  }
}

#curved-corner-topleft:before {
  top: 0;
  left: 0;
  box-shadow: -50px -50px 0 0 transparent;
  background-color: transparent;
}

#curved-corner-bottomright:before {
  bottom: 0;
  right: 0;
  box-shadow: 50px 50px 0 0 transparent;
  background-color: var(--piggle-red);
}

#curved-corner-bottomleft:before {
  bottom: 0;
  left: 0;
  box-shadow: -50px 50px 0 0 transparent;
  background-color: var(--piggle-yellow);
}

#curved-corner-topright:before {
  top: 0;
  right: 0;
  box-shadow: 50px -50px 0 0 transparent;
  background-color: var(--piggle-purple);
}
// end logo css

.primary-title-color {
  color: var(--purple-dark);
}

strong {
  color: var(--purple-dark);
  // color: rgba(63, 65, 114, 0.8);
  font-weight: normal !important;
}

h1.custom-section-h1 {
  font-family: "Merriweather";
  font-style: normal;
  /* Benefit Heading */
  text-align: center;
  color: var(--purple-dark);
  font-weight: 700;
  font-size: 51px;
  letter-spacing: -0.02em;
  order: 0;
}
